import React from "react"

const Header = () => (
  <header>
    <h1
      style={{
        letterSpacing: -2,
        lineHeight: `1em`,
        maxWidth: 620,
      }}
    >
      Product Engineer
      <br />
      Frontend & Mobile.
    </h1>
    <h2
      style={{
        fontSize: `1.4rem`,
        letterSpacing: -1,
        lineHeight: `1.625em`,
        fontWeight: `normal`,
        maxWidth: 716,
      }}
    >
      I'm a Mexican product engineer based in Madrid. I specialize in user
      experience design, interaction design, and user interface engineering and
      development for web and mobile apps.
    </h2>
  </header>
)

export default Header
