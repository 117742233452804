import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

const MenuItems = [
  { slug: "/profile/", text: "Profile Description" },
  { slug: "/skills/", text: "Skills & Software" },
  { slug: "/contact/", text: "Contact" },
]

const ListMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const Menu = path => {
  const LinkItem = styled.a`
    font-family: "basier_squaresemibold", "Helvetica Neue", Helvetica,
      sans-sans-serif;
    color: inherit;
    text-decoration: ${props =>
      path.path === props.href ? "underline" : "none"};
    :visited {
      color: inherit;
    }
    :hover {
      text-decoration: underline;
    }
  `
  const navigateToSection = (event, slug) => {
    event.preventDefault()
    navigate(slug)
  }
  return (
    <ListMenu>
      {MenuItems.map(item => {
        return (
          <li key={`item_${item.slug}`}>
            <LinkItem
              href={item.slug}
              key={item.slug}
              onClick={event => {
                navigateToSection(event, item.slug)
              }}
            >
              {item.text}
            </LinkItem>
          </li>
        )
      })}
    </ListMenu>
  )
}

export default Menu
