import React from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import SEO from "../components/seo"
import Menu from "../components/menu"
import styled from "styled-components"
import "./layout.css"
import "./theme.css"
import "../css/typography.css"

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 890px;
  margin: 0 auto;
`

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  @media (min-width: 890px) {
    flex-direction: row;
    padding-top: 2rem;
  }
`

const MenuContainer = styled.aside`
  flex-shrink: 0;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.8);
  margin-bottom: calc(1.45rem / 2);
  @media (prefers-color-scheme: dark) and (max-width: 890px) {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.8);
  }
  @media (min-width: 890px) {
    min-width: 30%;
    border: none;
    padding-bottom: 0;
  }
`

const ContentContainer = styled.section`
  p {
    max-width: 36em;
  }
`

const Footer = styled.footer`
  margin-top: auto;
`

const motionInit = {
  x: -3,
  opacity: 0,
}

const motionEnter = {
  x: 0,
  opacity: 1,
}

const motionTransition = {
  ease: "anticipate",
}

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Interaction Designer & User Interface Engineer"
        description="Mexican designer based in Madrid | Product Engineering, User Experience, Interaction Design, Hi-Fi Prototyping, UI Engineering"
      />
      <MainLayout>
        <Header siteTitle={data.site.siteMetadata.title} />
        <MainContainer>
          <MenuContainer>
            <Menu path={location.path} />
          </MenuContainer>
          <ContentContainer>
            <motion.div
              initial={motionInit}
              animate={motionEnter}
              transition={motionTransition}
            >
              {children}
            </motion.div>
          </ContentContainer>
        </MainContainer>
        <Footer>© {new Date().getFullYear()}</Footer>
      </MainLayout>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
